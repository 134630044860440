//import $ from 'jquery'
//import slick from 'slick-carousel'
import './autoreg'
import faqSchemeGenerator from './faqSchemeGenerator'

$(document).ready(function () {
  $('article.post-content').prependTo($('.main-text'))
  $('article.post-content h1').appendTo($('.for-h'))

  // burger menu
  $('.burger').on('click', function () {
    $(this).toggleClass('burger_active')
    $('.header__menu').slideToggle()
  })
// mobile burger menu header
$('.menu .menu-item-has-children').each(function () {
  if (parseInt($(window).width()) <= 992) {
    let used;
    $(".kill-list .menu-item-has-children").on("click", function (e) {
      if (used !== this) {
        used = this;
        e.preventDefault();
      }
    });
  }
});

// search field animation
$('.header__search-icon').on('click', function (e) {
$(this).removeClass('scale-show')
$(this).addClass('scale-hide')
$('#searchform').toggleClass('active')
$('.header__menu ul')
  .children()
  .each(function () {
    $(this).removeClass('scale-show')
    $(this).addClass('scale-hide')
  
  })
setTimeout(() => {
  $('.search-form__input').focus()
}, 100)
})

$('.search-form__close').on('click', function (e) {
$('.header__search-icon').addClass('scale-show')
$('.header__search-icon').removeClass('scale-hide')
$('#searchform').toggleClass('active')
$('.header__menu ul')
  .children()
  .each(function () {
    $(this).removeClass('scale-hide')
    $(this).addClass('scale-show')
    
  })
})


  // faq
  $('.faq .faq-item')
    .eq(0)
    .addClass('active')
    .find('.faq-item__body')
    .slideDown()

  $('.faq').on('click', function (e) {
    const $item = $(e.target).closest('.faq-item')

    if (!$item.length) return false

    if ($item.hasClass('active')) {
      $item.removeClass('active')
      $item.find('.faq-item__body').slideUp()
      return false
    }

    $(this)
      .find('.faq-item')
      .each(function () {
        $(this).removeClass('active')
        $(this).find('.faq-item__body').slideUp()
      })

    $item.toggleClass('active')
    $item.find('.faq-item__body').slideToggle()
  })

  faqSchemeGenerator(
    '.faq',
    '.faq-item',
    '.faq-item__header',
    '.faq-item__body'
  )

  // responsive
  function responsive() {
    if ($(document).width() <= 992) {
      if ($('.burger').hasClass('burger_active')) {
        $('.header__menu').show()
      } else {
        $('.header__menu').hide()
      }
    } else {
      $('.header__menu').show()
    }
  }

  responsive()

  $(window).resize(responsive)

  // table more button
  $('.table__more-button').on('click', function () {
    $(this)
      .parents('.table')
      .find('.table__body .table__row')
      .each(function () {
        $(this).css('display', 'flex')
      })
    $(this).remove()
  })

  // posts more button
  $('.list__button').on('click', function () {
    $(this)
      .parents('.list')
      .find('.list__body .post-item, .list__body .bonus-item')
      .each(function () {
        $(this).removeClass('hidden')
      })
    $(this).remove()
  })

  function activeRate() {
    $('.commentratingbox').toggleClass('active');
  }

  $(".rt").click(function () {
    var value = $(this).val();
    $('.cm-placeholder').text(value);
    activeRate();
  });

  $('.dwn').click(function () {
    activeRate()
  })

  $('.read-more-btn').click(function () {
    $('.otzyvy-text').toggleClass('active');
    $(this).toggleClass('active');
  })
})